import React, { useEffect, useState } from 'react';
import { StudioGame, StudioGameVoucher } from '../types/games';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { creditUser, deactivateVoucher, getGame, getVoucher, openSlot, updateGame } from '../services/games';

const GameDetail: React.FC = () => {

    let navigate = useNavigate();
    const { id } = useParams();
    const [voucher, setVoucher] = useState("")
    const [currentGame, setCurrentGame] = useState<StudioGame | undefined>(undefined)
    const [grid, setGrid] = useState<number[]>(currentGame?.slots?.map(x => x.index) || [])
    const [boardActivated, setBoardActivated] = useState(false)
    const [isSetting, setIsSetting] = useState(false)
    const [revealedSlots, setRevealedSlots] = useState<boolean[]>(
        currentGame?.slots?.map((x) => x.is_opened === true) || []
    );

    const [isResettingBoard, setIsResettingBoard] = useState(false)
    const [isCheckingVoucher, setIsCheckingVoucher] = useState(false)
    const [isRevealing, setIsRevealing] = useState(false)
    const [show, setShow] = useState(false)
    const [voucherInUse, setVoucherInUse] = useState<StudioGameVoucher | undefined>(undefined)
    const revealItem = (index: number) => {
        if (!boardActivated) {
            alert("Enter a voucher code to activate game board")
            return
        }
        setRevealedSlots((prevRevealedItems) => {
            const updatedRevealedItems = [...prevRevealedItems];
            updatedRevealedItems[index] = true;
            return updatedRevealedItems;
        });
        resetGameBoard(index)
    };


    const resetGameBoard = async (index: number) => {
        setIsResettingBoard(true)
        setIsSetting(true)
        setBoardActivated(false)

        let slot = [...currentGame?.slots || []][index]
        slot.is_opened = true
        slot.code = voucherInUse?.code
        slot.msisdn = voucherInUse?.msisdn

        try {
            // Update the game
            await updateGame(id + "", {
                is_active: true,
                won: currentGame?.won,
                left: currentGame!.total - currentGame!.won!
            });

            // Open the slot
            await openSlot(id + "", { ...slot, });

            // Credit the user
            await creditUser(voucherInUse?.msisdn + "", slot.slot_value + "");
            await deactivateVoucher(voucherInUse?.code + "")
            // Get the updated game
            const game = await getGame(id + "");
            setCurrentGame(game.data);
        } catch (error) {
            alert('An error occurred:' + JSON.stringify(error));
        } finally {
            setVoucherInUse(undefined)
            setIsResettingBoard(false)
            setIsSetting(false);
        }

    };

    useEffect(() => {
        getGame(id + "").then(game => {
            setCurrentGame(game.data)
        })
    }, [])

    useEffect(() => {

        setGrid(currentGame?.slots?.map(x => x.index) || [])
        setRevealedSlots(currentGame?.slots?.map((x) => x.is_opened === true) || [])

        const won = currentGame?.slots?.reduce((total, slot) => {
            if (slot.is_opened) {
                return total + slot.slot_value;
            }
            return total;
        }, 0);

        const left = currentGame?.slots?.reduce((total, slot) => {
            if (!slot.is_opened) {
                return total + slot.slot_value;
            }
            return total;
        }, 0);

        const copy = { ...currentGame!, "won": won, "left": left }
        if (copy.won != currentGame?.won) {
            setCurrentGame(copy)
        }

    }, [currentGame])


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsCheckingVoucher(true)
        getVoucher(voucher).then(res => {
            if (res.data && res.data.is_active) {
                setVoucherInUse(res.data)
                setBoardActivated(true)
                setVoucher("")
            } else {
                alert("The voucher has been used")
            }
        }).catch((error) => {
            //setBoardActivated(true)
            alert("Invalid voucher code")
        }).finally(() => {
            setIsCheckingVoucher(false)
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setVoucher(value);
    };


    if (!sessionStorage.getItem("token")) {
        navigate("/")
    }

    const revealAll = async () => {
        // Update the game
        setIsRevealing(true)
        try {
            await updateGame(id + "", {
                is_active: false,
                won: currentGame?.won,
                left: currentGame!.total - currentGame!.won!
            });
            const game = await getGame(id + "");
            setShow(true)
            setCurrentGame(game.data);
        } catch {
            alert("Error closing Game! try again!")
        } finally {
            setIsRevealing(false)
        }
    }
    return (
        <div className={`min-h-screen flex items-center justify-between px-10 px-4 sm:px-6 lg:px-16 ${boardActivated ? 'bg-black' : 'bg-blue-200'}`}>
            <div className='flex justify-center flex-col gap-5'>
                <div className=' bg-purple-500 text-center font-bold flex justify-center items-center py-5 px-2 w-[90%] rounded-[10px] font-poppins italic'>
                    <h2 className="text-[18px] font-extrabold text-black text-center whitespace-nowrap">GAME DETAILS</h2>
                </div>
                {GameDetailView(currentGame, boardActivated)}
                {isSetting ? <div className='bg-green-50 p-5 '>
                    <p>Setting Game Board.... </p>
                </div> : BoardActivationView(handleSubmit, voucher, handleInputChange, currentGame, isCheckingVoucher, isResettingBoard, boardActivated)
                }
                {isResettingBoard && <div className='p-3 flex animate-pulse'>
                    <p>Congratulation: Crediting user account and setting up new game</p>
                </div>}
                <button onClick={revealAll} className="mt-4 bg-gradient-radial from-blue-500 to-pink-200 hover:bg-green-700 text-black font-extrabold w-[90%] px-2 py-4 text-[20px] rounded-[10px] font-poppins italic">
                    {isRevealing ? "Loading..." : "REVEAL ALL"}
                </button>
            </div>

            <div className={`min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8 ${boardActivated ? 'bg-black' : 'bg-blue-200'}`}>

                <p className='bg-gradient-to-r from-blue-500 to-green-400 px-8 py-1 rounded-full mb-3 font-extrabold text-[22px] tracking-[3px]'>ELIEST LOTTO EAGLE 7</p>
                {GameSlotsView(grid, revealItem, revealedSlots, currentGame, show)}

            </div>
        </div>

    );
};

export default GameDetail;

function GameSlotsView(grid: number[], revealItem: (index: number) => void, revealedSlots: boolean[], currentGame: StudioGame | undefined, show: boolean) {
    return <div className="grid grid-cols-10 gap-1">
        {grid.map((number, index) => SlotItemView(index, revealItem, revealedSlots, currentGame, show))}
    </div>;
}

function SlotItemView(index: number, revealItem: (index: number) => void, revealedSlots: boolean[], currentGame: StudioGame | undefined, show: boolean) {
    const columnColors = ['bg-gradient-radial from-red-500 to-pink-500', 'bg-gradient-radial from-blue-500 to-pink-200', 'bg-gradient-radial from-yellow-200 to-green-800', 'bg-gradient-radial from-pink-400 to-blue-500', 'bg-gradient-radial from-yellow-200 to-green-400', 'bg-gradient-radial from-red-700 to-pink-500', 'bg-gradient-radial from-yellow-400 to-yellow-200', 'bg-gradient-radial from-purple-600 to-blue-600', 'bg-gradient-radial from-orange-300 to-orange-400', 'bg-gradient-radial from-pink-400 to-pink-300',];
    const columnIndex = index % columnColors.length;
    return <div
        key={index}
        onClick={() => revealItem(index)}
        className={`shadow-md p-3 text-center text-[18px] font-extrabold text-black rounded-full cursor-pointer ${revealedSlots[index] ? 'bg-green-800 text-white' : `${columnColors[columnIndex]}`} `}
    >
        {revealedSlots[index] || show ? currentGame?.slots?.[index]?.slot_value : currentGame?.slots?.[index]?.index}
    </div>;
}

function BoardActivationView(handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void, voucher: string, handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void, currentGame: StudioGame | undefined, isCheckingVoucher: boolean, isResettingBoard: boolean, boardActivated: boolean): React.ReactNode {
    return <div className={`${boardActivated ? 'bg-blue-600' : 'bg-gray-100'} p-5 w-[90%] rounded-md font-poppins italic`}>

        {boardActivated ? <div className='flex justify-center items-center'><Link className='text-[18px] font-extrabold text-black text-center whitespace-nowrap' to={"/games"}>NEW GAME</Link></div> : <><h3 className="text-xl font-semibold">Activate Game Board</h3>
            <Link className='mt-4 text-green-700 font-semibold  p-3 rounded-md' to={"/games"}>Back to Games</Link>
        </>}

        {boardActivated ? <div className='flex justify-center items-center'><p className='text-[18px] font-extrabold text-black text-center whitespace-nowrap'>ACTIVATE BOARD</p></div> : <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Player Voucher
                </label>
                <input
                    type="text"
                    id="voucher"
                    name="voucher"
                    value={voucher}
                    onChange={handleInputChange}
                    required
                    className="mt-1 p-2 w-full border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500" />
            </div>

            {currentGame?.is_active ? <button
                type="submit"
                disabled={isCheckingVoucher || isResettingBoard}
                className="bg-green-600 hover:bg-green-700 text-white font-semibold px-4 rounded-md py-2"
            >
                {isCheckingVoucher ? "Loading" : "Activate Board"}
            </button> : <div className='py-7 p-5 bg-red-100  flex-col justify-center space-y-10'>
                <p className='pb-3'>The Game has been closed!!! All slot has been revealed</p>
                <Link className='mt-4 bg-green-600 hover:bg-green-700 text-white font-semibold  p-3 rounded-md' to={"/games"}>Create New Game</Link>
            </div>}
        </form>}
    </div>;
}

function GameDetailView(currentGame: StudioGame | undefined, boardActivated: boolean) {
    return <div className={`flex justify-between gap-4 flex-col my-2 w-full ${boardActivated ? 'bg-black' : 'bg-blue-200'} font-poppins italic`}>
        <div className='bg-red-400 w-[90%] rounded-[10px]'>
            <div className='text-[20px] font-extrabold py-4 text-gray-900 text-center whitespace-nowrap'>
                JACKPOT:
                <p>{currentGame?.jackpot}</p>
            </div>
        </div>
        {/* <div>
                <p className='bg-pink-600'>Title</p>
                <p>{currentGame?.name}</p>
            </div> */}
        <div className='flex justify-between items-center w-[90%] gap-2'>

            <div className='bg-pink-400 w-1/2 text-[18px] font-extrabold text-gray-900 text-center whitespace-nowrap rounded-[10px]'>
                WON:
                <p>{currentGame?.won}</p>
            </div>
            <div className=' bg-gradient-radial from-yellow-200 to-green-800 w-1/2 text-[18px] font-extrabold text-gray-900 text-center whitespace-nowrap rounded-[10px] ' >
                LEFT:
                <p>{(currentGame?.left || 0) - (currentGame?.jackpot || 0)}</p>
            </div>
        </div>
    </div>;
}

