import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

type FormData = {
    email: string;
    password: string;
};


const loginUser = async (formData: FormData) => {
    const apiUrl = `${process.env.REACT_APP_STUDIO_BASE_URL}studios/login`
    const response = await axios.post(apiUrl, formData);
    return response.data;
};

function Login() {
    const { register, handleSubmit } = useForm<FormData>();
    let navigate = useNavigate();

    const { mutate, isLoading, isError, data: responseData } = useMutation(loginUser, {
        onSuccess: ({data}) => {
            storeValues("token", data["token"])
            storeValues("studio", JSON.stringify(data["studio"]))
            if (data["studio"].name.includes("Partner")){
                navigate("/data")

            }else{
                navigate("/games")
            }
        },
    });

    const onSubmit = (data: FormData) => {
        mutate(data);
    };

    function storeValues(key: string, value: string) {
        sessionStorage.setItem(key, value)
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Studio Login
                    </h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">
                                Email address
                            </label>
                            <input
                                {...register('email', { required: true })}
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                                placeholder="Email address"
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Password
                            </label>
                            <input
                                {...register('password', { required: true })}
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                                placeholder="Password"
                            />
                        </div>
                    </div>

                    {isError && (
                        <p className="mt-2 text-sm text-red-600">
                            An error occurred during login.
                        </p>
                    )}

                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Logging in...' : 'Sign in'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
