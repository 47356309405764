import React, { useEffect, useState } from 'react'
import { GameSlot, StudioGame, StudioGameVoucher } from '../types/games'
import { fetchVouchers, formatDateStringWithMoment, getOpenedSlots } from '../services/games'
import { Query } from 'react-query'
import moment from 'moment'


type VProps = {
    vouchers: StudioGameVoucher[]
}
function VoucherList({ vouchers }: VProps) {

    const [query, setQuery] = useState("")
    const [Vvoucher, setVvoucher] = useState<StudioGameVoucher[]>(vouchers)
    
    useEffect(() => {

       const v = vouchers.filter(x=>(x.msisdn +"").includes(query))
       setVvoucher(v)
    }, [query])
    
    
    return (
        <div>
            <div className='p-5'>
                <input placeholder='Search By phone number' className='p-2 bg-gray-100' onChange={e=> setQuery(e.target.value)}/> 
            </div>

            <table className="w-full text-sm text-left text-gray-500 ">
                <thead className='text-xs table-fixed text-gray-700 uppercase bg-gray-100  sticky top-0'>
                    <tr className='sticky top-0'>
                        <th scope="col" className="p-2">Id</th>
                        <th scope="col" className="p-2">Phone Number</th>
                        <th scope="col" className="p-4">Time</th>
                        <th scope="col" className="p-4">Voucher coDE</th>
                        <th scope="col" className="p-4">Status</th>
                        <th scope="col" className="p-4"></th>
                    </tr>
                </thead>
                <tbody>
                    {Vvoucher.map(voucher =>
                        <tr className='border-b border-gray-200 dark:border-gray-700' key={voucher.id}>
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">{voucher.id}</td>
                            <td className="px-4">{voucher.msisdn}</td>
                            <td className="px-4">{voucher.created ? formatDateStringWithMoment(voucher.created) : ""}</td>
                            <td className="px-4">{voucher.is_active ? "xxxxxx" : voucher.code}</td>
                            <td className="px-4">{voucher.is_active}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}


type SProps = {
    slots: GameSlot[]
}
function StudioSlotList({ slots }: SProps) {

    const [query, setQuery] = useState("")
    const [sslots, setSSlots] = useState<GameSlot[]>(slots)
    
    useEffect(() => {

       const v = slots.filter(x=>(x.msisdn +"").includes(query))
       setSSlots(v)
    }, [query])

    return (
        <div>
            
              <div className='p-5'>
                <input placeholder='Search By phone number' className='p-2 bg-gray-100' onChange={e=> setQuery(e.target.value)}/> 
            </div>

            <table className="w-full text-sm text-left text-gray-500 ">
                <thead className='text-xs table-fixed text-gray-700 uppercase bg-gray-100  sticky top-0'>
                    <tr className='sticky top-0'>
                        <th scope="col" className="p-2 sticky top-0 ">Id</th>
                        <th scope="col" className="p-2 sticky top-0 ">User Phone</th>
                        <th scope="col" className="p-2 sticky top-0 ">CODE USED</th>
                        <th scope="col" className="p-4 sticky top-0 ">Amount Won</th>
                        <th scope="col" className="p-4 sticky top-0 ">Time</th>
                        <th scope="col" className="p-4 sticky top-0 "></th>
                    </tr>
                </thead>
                <tbody>
                    {sslots.map(slot => <tr className='border-b border-gray-200 dark:border-gray-700' key={slot.id}>
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">{slot.id}</td>
                        <td className="px-4">{slot.msisdn}</td>
                        <td className="px-4">{slot.code}</td>
                        <td className="px-4">{slot.slot_value}</td>
                        <td className="px-4">{slot.date_opened ? formatDateStringWithMoment(slot.date_opened) : ""}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}

const DataPage: React.FC = () => {
    const today = new Date().toISOString().slice(0, 10);

    const [vouchers, setVouchers] = useState<StudioGameVoucher[] | undefined>(undefined)
    const [slots, setSlots] = useState<GameSlot[] | undefined>(undefined)

    const [filteredVouchers, setFilteredVouchers] = useState<StudioGameVoucher[] | undefined>(undefined)
    const [filteredSlots, setFilteredSlots] = useState<GameSlot[] | undefined>(undefined)

    const [query, setQuery] = useState<string | undefined>(undefined)

    const [fromDate, setFromDate] = useState<string>(today)
    const [toDate, setToDate] = useState<string>(today)

    const USSD_GAME = "GAMES_PLAYED"
    const STUDIO_GAME = "STUDIO_PLAYED"
    const ACCOUNT_VIEW = "ACCOUNT_VIEW"

    useEffect(() => {
        fetchVouchers().then(res => {
            setVouchers(res.data)
        })
        getOpenedSlots().then(res => setSlots(res.data))
    }, [])


    useEffect(() => {  if (query) {  }
    }, [query])

    useEffect(() => {
        setFilteredSlots(filterGameSlotsByDateRange(slots, fromDate, toDate))
        setFilteredVouchers(voucherByDateRange(vouchers, fromDate, toDate))
    }, [fromDate, toDate])


    function filterGameSlotsByDateRange(gameSlots: GameSlot[] = [], startDate: string, endDate: string): GameSlot[] {
        return gameSlots.filter((slot) => {
            const slotDate = moment(slot.date_opened);
            return (
                slotDate.isSameOrAfter(startDate, 'day') && slotDate.isSameOrBefore(endDate, 'day')
            );
        });
    }

    function voucherByDateRange(gameSlots: StudioGameVoucher[] = [], startDate: string, endDate: string): StudioGameVoucher[] {
        return gameSlots.filter((slot) => {
            const slotDate = moment(slot.created);
            return (
                slotDate.isSameOrAfter(startDate, 'day') && slotDate.isSameOrBefore(endDate, 'day')
            );
        });
    }

    const SummaryLabel = <h6>Summary: <b>{fromDate} </b> to <b>{toDate}</b> </h6>
    return (
        <div className='flex w-full  md:min-h-screen max-h-screen'>
            <div className='w-full md:w-4/12 flex flex-col space-y-10 justify-center items-center bg-gray-100 md:min-h-screen'>
                <div className='border p-5'>
                    <form className='space-y-5'>
                        <div>
                            <p>What is your query?</p>
                            <select className='rounded shadow p-2 bg-gray-50' onChange={e => setQuery(e.target.value)}>
                                <option value={undefined} >Choose an option</option>
                                <option value={USSD_GAME}>Number of player that played Eagle 7</option>
                                <option value={STUDIO_GAME}>Number of Studio Slot Games played</option>
                                <option value={ACCOUNT_VIEW}>Account Summary</option>
                            </select>
                        </div>
                        <div className='flex space-x-5'>
                            <div className='flex-1'>
                                <p>From</p>
                                <input value={fromDate}
                                    type='date' className='rounded shadow p-2 bg-gray-50' onChange={e => setFromDate(e.target.value)} />
                            </div>
                            <div className='flex-1'>
                                <p>To</p>
                                <input value={toDate}
                                    type='date' className='rounded shadow p-2 bg-gray-50' onChange={e => setToDate(e.target.value)} />
                            </div>
                        </div>
                    </form>
                </div>

                {query == USSD_GAME && <div className='border p-5'>
                    <div>
                        {SummaryLabel}
                        <div>Games Play Count: {filteredVouchers?.length} </div>
                        <div>Income Generated: NGN{(filteredVouchers?.length ?? 0) * 50.0}</div>
                    </div>
                </div>}

                {query == STUDIO_GAME && <div className='border p-5'>
                    <div>
                        {SummaryLabel}
                        <div>Games Play Count: {filteredSlots?.length} </div>
                        <div>Dispensed: NGN{[...filteredSlots || []].reduce((sum, slot) => sum + slot.slot_value, 0)} </div>
                    </div>
                </div>}
                {query == ACCOUNT_VIEW && <div className='space-y-10'>
                    <div>
                        {SummaryLabel}
                        <div>Games Play Count: {filteredVouchers?.length} </div>
                        <div>Income Generated: {(filteredVouchers?.length ?? 0) * 50}</div>
                    </div>
                    <div>
                    {SummaryLabel}
                        <div>Games Play Count: {filteredSlots?.length} </div>
                        <div>Dispensed: {[...filteredSlots || []].reduce((sum, slot) => sum + slot.slot_value, 0)} </div>
                    </div>
                </div>
                }

            </div>
            <div className='w-full md:w-8/12  flex justify-center  bg-green-50 overflow-y-scroll'>
                <div className='overflow-y-scroll'>
                    {query == USSD_GAME && <VoucherList vouchers={filteredVouchers || []} />}
                    {query == STUDIO_GAME && <StudioSlotList slots={filteredSlots || []} />}
                    {query == ACCOUNT_VIEW &&
                        <div className='space-y-10'>
                            <StudioSlotList slots={filteredSlots || []} />
                            <VoucherList vouchers={filteredVouchers || []} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}



export default DataPage