import axios, { AxiosError } from "axios";
import { CreditUserHttpResponse, DeactivateVoucherHttpResponse, GamesSlotsHttpResponse, OpenSlotHttpResponse, StudioGameHttpResponse, StudioGamesHttpResponse, StudioVoucherHttpResponse, UpdateGameHttpResponse, UpdateStudioGamePayload, VouchersHttpResponse } from "../types/games";
import moment from "moment";


export async function fetchPreviousGames(): Promise<StudioGamesHttpResponse> {
    try {
        const email = sessionStorage.getItem('token');
        if (!email) {
            throw new Error('Studio email not found in sessionStorage');
        }
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}studiogames/by-studio-email/${email}`;
        const response = await axios.get<StudioGamesHttpResponse>(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching previous games:', error);
        throw error; 
    }
}

export async function createNewGame(gameData: object): Promise<StudioGameHttpResponse> {
    try {
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}studiogames`;
        const response = await axios.post(url, gameData);
        return response.data;
    } catch (error) {
        console.error('Error creating a new game:', error);
        throw error; 
    }
}

export async function getGame(gameId: string): Promise<StudioGameHttpResponse> {
    try {
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}studiogames/${gameId}`;
        const response = await axios.get<StudioGameHttpResponse>(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching previous games:', error);
        throw error; 
    }
}

interface ErrorResponse {
    message: string;
    status: string;
    data: any;
  }
export async function getVoucher(code: string): Promise<StudioVoucherHttpResponse> {
    try {
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}vouchers/${code}`;
        const response = await axios.get<StudioVoucherHttpResponse>(url);        
        return response.data;
    } catch (error) {
        const axiosError = error as AxiosError
        const errorResponse = axiosError.response?.data;
        return Promise.reject(errorResponse);
    }
}

export async function updateGame(gameId: string, body: UpdateStudioGamePayload): Promise<UpdateGameHttpResponse> {
    try {
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}studiogames/${gameId}`;
        const response = await axios.put(url, body);
        return response.data;
    } catch (error) {
       
        console.error('Error fetching previous games:', error);
        throw error; 
    }
}

export async function openSlot(gameId: string, body:object): Promise<OpenSlotHttpResponse> {
    try {
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}studiogames/${gameId}/open-slot`;
        const response = await axios.post<OpenSlotHttpResponse>(url, body);
        return response.data;
    } catch (error) {
        console.error('Error fetching previous games:', error);
        throw error; 
    }

}

export async function creditUser(msisdn: string, amount: string): Promise<CreditUserHttpResponse> {
    try {
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}users/credit-win-wallet/${msisdn}/${amount}`;
        const response = await axios.post(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching previous games:', error);
        throw error; 
    }
}

export async function deactivateVoucher(code: string): Promise<DeactivateVoucherHttpResponse> {
    try {
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}vouchers/${code}/used`;
        const response = await axios.post(url);        
        return response.data;
    } catch (error) {
        console.error('Error fetching previous games:', error);
        throw error; 
    }
}

export async function fetchVouchers(): Promise<VouchersHttpResponse> {
    try {
        const email = sessionStorage.getItem('token');
        if (!email) {
            throw new Error('Studio email not found in sessionStorage');
        }
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}vouchers`;
        const response = await axios.get<VouchersHttpResponse>(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching previous games:', error);
        throw error; 
    }
}

export async function getOpenedSlots(): Promise<GamesSlotsHttpResponse> {
    try {
        const email = sessionStorage.getItem('token');
        if (!email) {
            throw new Error('Studio email not found in sessionStorage');
        }
        const url = `${process.env.REACT_APP_STUDIO_BASE_URL}slots/true`;
        const response = await axios.get<GamesSlotsHttpResponse>(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching previous games:', error);
        throw error; 
    }
}

export function formatDateStringWithMoment(inputDateString: string) {
    const inputDate = moment(inputDateString, moment.ISO_8601);
  
    if (inputDate.isValid()) {
      return inputDate.toDate().toDateString();
    } else {
      return 'Invalid date';
    }
  }