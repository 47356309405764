import React from 'react';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 mb-4">Welcome to Eliest Studio Games</h2>
      <Link to="/login">
        <button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-md">
          Get Started
        </button>
      </Link>
    </div>
  );
};

export default Home;
