import React from 'react';
import 'tailwindcss/tailwind.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from './components/Login';
import { queryClient, QueryClientProvider } from './queryClient';
import Games from './components/Games';
import GameDetail from './components/GameDetail';
import Guard from './components/Home';
import DataPage from './components/DataPage';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Guard/>
  },{
    path: "/login",
    element: <Login />
  },
  {
    path: "/games",
    element: <Games />
  },
  {
    path: "/data",
    element: <DataPage />
  },
  {
    path: "/games/:id",
    element: <GameDetail />
  },
]);


function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>

  );
}

export default App;
