import React, { useEffect, useState, useMemo } from 'react';
import { StudioGame } from '../types/games';
import { createNewGame, fetchPreviousGames } from '../services/games';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from "react-paginate";


const Games: React.FC = () => {
    let navigate = useNavigate();

    const [games, setGames] = useState<StudioGame[]>([]);
    const [isLoadingGames, setIsLoadingGames] = useState(false)
    const [isCreatingGames, setIsCreatingGames] = useState(false)

    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10)
    const [view, setView] = useState('')
    const [dateFilter, setDateFilter] = useState("");

    const [isFiltered, setIsFiltered] = useState(false);


    const renderDefaultComponent = () => {
        setIsFiltered(false);
    };

    useEffect(() => {
        setTimeout(() => {
            renderDefaultComponent()
        }, 4000);
    }, []);

    const PER_PAGE = perPage;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(games.length / PER_PAGE);

    function handlePageClick({ selected: selectedPage }: { selected: number }) {
        setCurrentPage(selectedPage);
    }

    const handlePageData = () => {
        setPerPage(Number(view))
    }

    const parseDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const time = date.getHours();

        return { day, month, year, time };
    };

    const handleFilterData = () => {
        const dateFilterParsed = parseDate(dateFilter);
        const filteredData = games?.filter((item) => {
            const itemDateParsed = parseDate(item?.created);
            return (
                itemDateParsed.day === dateFilterParsed.day &&
                itemDateParsed.month === dateFilterParsed.month &&
                itemDateParsed.year === dateFilterParsed.year
            );
        });
        setIsFiltered(true);
        return filteredData;
    };

    const item = useMemo(handleFilterData, [dateFilter, games]);


    function loadGames() {
        setIsLoadingGames(true)
        fetchPreviousGames().then((data) => {
            setGames(data.data!.reverse());
            setIsLoadingGames(false)
        }).catch(() => {
            alert("Error loading your games")
        })
    }
    const [newGame, setNewGame] = useState<StudioGame>({
        id: 0,
        name: '',
        total: 0,
        jackpot: 0,
        won: 0,
        left: 0,
        is_active: true,
        studio_email: sessionStorage.getItem("token"),
        slots: []
    });

    useEffect(() => {
        loadGames()
    }, []);



    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewGame((prevGame) => ({
            ...prevGame,
            [name]: name === 'name' ? value : parseInt(value, 10),
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsCreatingGames(true)
        if (newGame.name && newGame.total >= 0 && newGame.jackpot) {
            newGame.studio_email = sessionStorage.getItem("token")
            createNewGame(newGame).then((res) => {
                loadGames()
            }).then(() => setIsCreatingGames(false)
            ).catch(() => {
                alert("Error creating a new game")
            })
        } else {
            console.log(newGame)
        }
    };

    if (!sessionStorage.getItem("token")) {
        navigate("/")
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className='w-4/12 flex justify-center'>
                <div className='bg-green-50 p-5 w-fit'>
                    <h3 className="text-xl font-semibold">Create a New Game</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={newGame.name}
                                onChange={handleInputChange}
                                required
                                className="mt-1 p-2 w-full border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500"
                            />
                        </div>
                        <div className='flex space-x-5'>
                            <div className="mb-4">
                                <label htmlFor="total" className="block text-sm font-medium text-gray-700">
                                    Total
                                </label>
                                <input
                                    type="number"
                                    id="total"
                                    name="total"
                                    value={newGame.total}
                                    onChange={handleInputChange}
                                    required
                                    className="mt-1 p-2 w-full border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="won" className="block text-sm font-medium text-gray-700">
                                    Jackpot
                                </label>
                                <input
                                    type="number"
                                    id="jackpot"
                                    name="jackpot"
                                    value={newGame.jackpot}
                                    onChange={handleInputChange}
                                    required
                                    className="mt-1 p-2 w-full border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500"
                                />
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="bg-green-600 hover:bg-green-700 text-white font-semibold px-4 rounded-md py-2"
                        >
                            {isCreatingGames ? "loading..." : "Create Game"}
                        </button>
                    </form>
                </div>
            </div>

            <div className='w-8/12 bg-green-100 flex justify-center'>
                <div>
                    <div className="mb-4">
                        <div className='flex justify-between items-center'>
                            <h3 className="text-xl font-semibold mb-2">Game List</h3>
                            <div className='flex justify-center items-center gap-[5px]'>
                                <p>Filter by date:</p>
                                <input type="date" className='px-[5px] rounded-[5px] border-[2px] border-green-500' value={dateFilter} onChange={e => setDateFilter(e.target.value)} />
                            </div>
                        </div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className='text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400'>
                                    <tr>
                                        <th scope="col" className="p-2">Name</th>
                                        <th scope="col" className="p-2">Total</th>
                                        <th scope="col" className="p-4">Won</th>
                                        <th scope="col" className="p-4">Left</th>
                                        <th scope="col" className="p-4">Date</th>
                                        <th scope="col" className="p-4"></th>
                                    </tr>
                                </thead>
                                {isLoadingGames ? <tbody>
                                    <tr className='border-b border-gray-200 dark:border-gray-700' key="loading">
                                        <td colSpan={5} className='text-green-500 text-lg text-center'>Loading...</td>
                                    </tr>
                                </tbody> : <>
                                       { !isFiltered ?
                                        <tbody>
                                            {games?.slice(offset, offset + PER_PAGE).map((game) => {
                                                const dateString = game?.created;
                                                const date = new Date(Date.parse(dateString));
                                                const readableDate = date.toLocaleDateString();
                                                return (
                                                    <tr className='border-b border-gray-200 dark:border-gray-700' key={game.id}>
                                                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">{game.name}</td>
                                                        <td className="px-4">{game.total}</td>
                                                        <td className="px-4">{game.won}</td>
                                                        <td className="px-4">{game.left}</td>
                                                        <td className="px-4">{readableDate}</td>
                                                        <td className="px-4 text-green-500 text-sm">
                                                            <Link to={`/games/${game.id}`} className='text-sm'>Details</Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody> : <tbody>
                                                {item?.slice(offset, offset + PER_PAGE).map((game) => {
                                                    const dateString = game?.created;
                                                    const date = new Date(Date.parse(dateString));
                                                    const readableDate = date.toLocaleDateString();
                                                    return (
                                                        <tr className='border-b border-gray-200 dark:border-gray-700' key={game.id}>
                                                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">{game.name}</td>
                                                            <td className="px-4">{game.total}</td>
                                                            <td className="px-4">{game.won}</td>
                                                            <td className="px-4">{game.left}</td>
                                                            <td className="px-4">{readableDate}</td>
                                                            <td className="px-4 text-green-500 text-sm">
                                                                <Link to={`/games/${game.id}`} className='text-sm'>Details</Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>}
                                        <button onClick={renderDefaultComponent} className='bg-green-500 rounded-[5px] text-white px-[10px] py-[5px] mt-[5px] font-[700]'>See all</button>
                                </>
                                }
                            </table>
                            <div className="flex items-center flex-col justify-center w-full mt-3 gap-2">
                                <div className='flex justify-center items-center gap-[5px]'>
                                    <input type="number" className='px-[5px] rounded-[5px] border-[2px] border-green-500' value={view} onChange={e => setView(e.target.value)} placeholder='number per page' />
                                    <button className='bg-green-500 rounded-[5px] px-[10px] font-[700] text-white' onClick={handlePageData}>Submit</button>
                                </div>
                                <ReactPaginate
                                    previousLabel={"<- Prev"}
                                    nextLabel={"Next ->"}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination_link"}
                                    nextLinkClassName={"pagination_link"}
                                    disabledClassName={"pagination_link--disabled"}
                                    activeClassName={"pagination_link--active"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Games;
